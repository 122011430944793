<template>
  <ClientOnly>
    <font-awesome-icon v-if="loadingState" icon="fa-light fa-spinner" class="fa-pulse text-[3rem] text-gray-300" />
    <NuxtLink v-else to="/" v-bind="{ ...$props, ...$attrs }">
      <img :src="imageUrl" :class="size" />
    </NuxtLink>
  </ClientOnly>
</template>

<script setup>
  defineOptions({
    inheritAttrs: false,
  })

  const props = defineProps({
    size: {
      type: String,
      default: "h-7",
    },

    variant: {
      type: String,
      default: "full",
    },
    loading: {
      type: Boolean,
      default: false
    }
  })

  const colorMode = useColorMode()
  const loadingState = ref(props.loading)

  const imageUrl = computed(() => {
    if (props.variant == "full") {
      return colorMode.value == "dark"
        ? "/images/logo-dark-background.svg"
        : "/images/logo-light-background.svg"
    } else if (props.variant == "square") {
      return colorMode.value == "dark"
        ? "/images/logo-square-dark-background.svg"
        : "/images/logo-square-light-background.svg"
    }
  })

  watch(() => props.loading, (value) => {
    loadingState.value = value
  })
</script>
